/* Define color variables at the top */
:root {
  /* --bg-primary: #3E1E68;
  --bg-accent-1: #735de5;
  --bg-accent-2: #ffe1ad;
  --bg-accent-3: #583C87; */
  --bg-primary: #161616;
  --bg-accent-1: #9b9b9b;
  --bg-accent-2: #747474;
  --bg-accent-3: #4e4e4e;
}

/* Add SVG filter for noise */
@keyframes noise {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: 
      radial-gradient(
        circle,
        var(--bg-accent-3) 0%,
        var(--bg-primary) 100%
      );
    overflow: hidden;
    /* Add noise effect */
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)'/%3E%3C/svg%3E");
        opacity: 0.05;
        mix-blend-mode: overlay;
        pointer-events: none;
        animation: noise 0.5s infinite;
    }
}

.background span {
    width: 3vmin;
    height: 3vmin;
    border-radius: 3vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 30;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.background span:nth-child(0) {
    color: var(--bg-accent-1);
    top: 74%;
    left: 2%;
    animation-duration: 62s;
    animation-delay: -83s;
    transform-origin: 16vw -17vh;
    box-shadow: -6vmin 0 1.0878004719418783vmin currentColor;
}
.background span:nth-child(1) {
    color: var(--bg-accent-2);
    top: 32%;
    left: 70%;
    animation-duration: 133s;
    animation-delay: -104s;
    transform-origin: 6vw -2vh;
    box-shadow: 6vmin 0 1.6577360654038036vmin currentColor;
}
.background span:nth-child(2) {
    color: var(--bg-accent-3);
    top: 38%;
    left: 94%;
    animation-duration: 128s;
    animation-delay: -108s;
    transform-origin: -7vw -5vh;
    box-shadow: 6vmin 0 1.7324696508058484vmin currentColor;
}
.background span:nth-child(3) {
    color: var(--bg-accent-1);
    top: 92%;
    left: 83%;
    animation-duration: 118s;
    animation-delay: -92s;
    transform-origin: -9vw -16vh;
    box-shadow: -6vmin 0 0.8163595744154979vmin currentColor;
}
.background span:nth-child(4) {
    color: var(--bg-accent-3);
    top: 87%;
    left: 23%;
    animation-duration: 46s;
    animation-delay: -87s;
    transform-origin: -17vw 1vh;
    box-shadow: -6vmin 0 1.276889459991023vmin currentColor;
}
.background span:nth-child(5) {
    color: var(--bg-accent-1);
    top: 19%;
    left: 69%;
    animation-duration: 103s;
    animation-delay: -43s;
    transform-origin: -8vw 17vh;
    box-shadow: 6vmin 0 1.649509713059974vmin currentColor;
}
.background span:nth-child(6) {
    color: var(--bg-accent-2);
    top: 65%;
    left: 48%;
    animation-duration: 202s;
    animation-delay: -13s;
    transform-origin: -14vw 16vh;
    box-shadow: 6vmin 0 1.2615508400120192vmin currentColor;
}
.background span:nth-child(7) {
    color: var(--bg-accent-1);
    top: 89%;
    left: 78%;
    animation-duration: 29s;
    animation-delay: -63s;
    transform-origin: -7vw 0vh;
    box-shadow: 6vmin 0 1.318979945849972vmin currentColor;
}
.background span:nth-child(8) {
    color: var(--bg-accent-2);
    top: 63%;
    left: 91%;
    animation-duration: 64s;
    animation-delay: -157s;
    transform-origin: 23vw 24vh;
    box-shadow: -6vmin 0 1.1131500396254836vmin currentColor;
}
.background span:nth-child(9) {
    color: var(--bg-accent-1);
    top: 55%;
    left: 21%;
    animation-duration: 116s;
    animation-delay: -68s;
    transform-origin: -23vw -20vh;
    box-shadow: 6vmin 0 1.6422369427404726vmin currentColor;
}
.background span:nth-child(10) {
    color: var(--bg-accent-1);
    top: 21%;
    left: 67%;
    animation-duration: 98s;
    animation-delay: -60s;
    transform-origin: 16vw -3vh;
    box-shadow: 6vmin 0 1.2427930547340778vmin currentColor;
}
.background span:nth-child(11) {
    color: var(--bg-accent-3);
    top: 56%;
    left: 17%;
    animation-duration: 153s;
    animation-delay: -104s;
    transform-origin: -12vw 13vh;
    box-shadow: -6vmin 0 1.5424328201123572vmin currentColor;
}
.background span:nth-child(12) {
    color: var(--bg-accent-1);
    top: 95%;
    left: 9%;
    animation-duration: 125s;
    animation-delay: -43s;
    transform-origin: 15vw 19vh;
    box-shadow: 6vmin 0 1.2422060270401556vmin currentColor;
}
.background span:nth-child(13) {
    color: var(--bg-accent-1);
    top: 95%;
    left: 32%;
    animation-duration: 156s;
    animation-delay: -62s;
    transform-origin: 9vw 13vh;
    box-shadow: -6vmin 0 1.0697392536635832vmin currentColor;
}
.background span:nth-child(14) {
    color: var(--bg-accent-3);
    top: 39%;
    left: 88%;
    animation-duration: 137s;
    animation-delay: -109s;
    transform-origin: 16vw -7vh;
    box-shadow: 6vmin 0 1.5152235368735212vmin currentColor;
}
.background span:nth-child(15) {
    color: var(--bg-accent-2);
    top: 38%;
    left: 21%;
    animation-duration: 187s;
    animation-delay: -184s;
    transform-origin: -18vw 13vh;
    box-shadow: 6vmin 0 1.0470655643915425vmin currentColor;
}
.background span:nth-child(16) {
    color: var(--bg-accent-1);
    top: 17%;
    left: 65%;
    animation-duration: 78s;
    animation-delay: -112s;
    transform-origin: -12vw -4vh;
    box-shadow: -6vmin 0 1.198073407309229vmin currentColor;
}
.background span:nth-child(17) {
    color: var(--bg-accent-2);
    top: 15%;
    left: 84%;
    animation-duration: 72s;
    animation-delay: -161s;
    transform-origin: 19vw -12vh;
    box-shadow: 6vmin 0 1.738011969341745vmin currentColor;
}
.background span:nth-child(18) {
    color: var(--bg-accent-1);
    top: 91%;
    left: 65%;
    animation-duration: 70s;
    animation-delay: -181s;
    transform-origin: -9vw 21vh;
    box-shadow: -6vmin 0 1.321781064082989vmin currentColor;
}
.background span:nth-child(19) {
    color: var(--bg-accent-2);
    top: 23%;
    left: 15%;
    animation-duration: 134s;
    animation-delay: -64s;
    transform-origin: -13vw -9vh;
    box-shadow: -6vmin 0 0.9940064325463109vmin currentColor;
}
.background span:nth-child(20) {
    color: var(--bg-accent-1);
    top: 16%;
    left: 67%;
    animation-duration: 43s;
    animation-delay: -39s;
    transform-origin: 25vw -15vh;
    box-shadow: 6vmin 0 1.7014099657195663vmin currentColor;
}
.background span:nth-child(21) {
    color: var(--bg-accent-1);
    top: 67%;
    left: 91%;
    animation-duration: 129s;
    animation-delay: -179s;
    transform-origin: -10vw -21vh;
    box-shadow: -6vmin 0 1.2298243211727136vmin currentColor;
}
.background span:nth-child(22) {
    color: var(--bg-accent-1);
    top: 94%;
    left: 12%;
    animation-duration: 151s;
    animation-delay: -49s;
    transform-origin: 23vw 12vh;
    box-shadow: 6vmin 0 1.0842511790071985vmin currentColor;
}
.background span:nth-child(23) {
    color: var(--bg-accent-2);
    top: 34%;
    left: 9%;
    animation-duration: 46s;
    animation-delay: -73s;
    transform-origin: 0vw -24vh;
    box-shadow: -6vmin 0 1.1868432867385517vmin currentColor;
}
.background span:nth-child(24) {
    color: var(--bg-accent-1);
    top: 17%;
    left: 2%;
    animation-duration: 201s;
    animation-delay: -16s;
    transform-origin: 13vw 23vh;
    box-shadow: 6vmin 0 1.401021469828001vmin currentColor;
}
.background span:nth-child(25) {
    color: var(--bg-accent-2);
    top: 85%;
    left: 12%;
    animation-duration: 153s;
    animation-delay: -163s;
    transform-origin: -13vw 2vh;
    box-shadow: -6vmin 0 1.0608909061437604vmin currentColor;
}
.background span:nth-child(26) {
    color: var(--bg-accent-1);
    top: 9%;
    left: 60%;
    animation-duration: 130s;
    animation-delay: -37s;
    transform-origin: -1vw 0vh;
    box-shadow: -6vmin 0 1.194486792531212vmin currentColor;
}
.background span:nth-child(27) {
    color: var(--bg-accent-1);
    top: 39%;
    left: 95%;
    animation-duration: 201s;
    animation-delay: -113s;
    transform-origin: 10vw 24vh;
    box-shadow: 6vmin 0 1.2392506803842096vmin currentColor;
}
.background span:nth-child(28) {
    color: var(--bg-accent-1);
    top: 15%;
    left: 32%;
    animation-duration: 87s;
    animation-delay: -140s;
    transform-origin: 15vw -24vh;
    box-shadow: -6vmin 0 1.0691855407729562vmin currentColor;
}
.background span:nth-child(29) {
    color: var(--bg-accent-1);
    top: 17%;
    left: 49%;
    animation-duration: 118s;
    animation-delay: -102s;
    transform-origin: 25vw -16vh;
    box-shadow: -6vmin 0 0.7556522880977101vmin currentColor;
}
.background span:nth-child(30) {
    color: var(--bg-accent-3);
    top: 13%;
    left: 23%;
    animation-duration: 11s;
    animation-delay: -190s;
    transform-origin: -23vw 23vh;
    box-shadow: 6vmin 0 0.882369020282509vmin currentColor;
}
.background span:nth-child(31) {
    color: var(--bg-accent-3);
    top: 14%;
    left: 25%;
    animation-duration: 76s;
    animation-delay: -178s;
    transform-origin: -15vw 12vh;
    box-shadow: 6vmin 0 0.954924538800416vmin currentColor;
}
.background span:nth-child(32) {
    color: var(--bg-accent-3);
    top: 72%;
    left: 89%;
    animation-duration: 24s;
    animation-delay: -39s;
    transform-origin: 15vw -2vh;
    box-shadow: -6vmin 0 1.6317477668001303vmin currentColor;
}
.background span:nth-child(33) {
    color: var(--bg-accent-1);
    top: 69%;
    left: 61%;
    animation-duration: 70s;
    animation-delay: -137s;
    transform-origin: 1vw 1vh;
    box-shadow: -6vmin 0 0.9114041526828784vmin currentColor;
}
.background span:nth-child(34) {
    color: var(--bg-accent-3);
    top: 76%;
    left: 79%;
    animation-duration: 178s;
    animation-delay: -152s;
    transform-origin: -1vw -24vh;
    box-shadow: -6vmin 0 1.1511211529925491vmin currentColor;
}
.background span:nth-child(35) {
    color: var(--bg-accent-1);
    top: 94%;
    left: 41%;
    animation-duration: 14s;
    animation-delay: -23s;
    transform-origin: 5vw -1vh;
    box-shadow: 6vmin 0 1.086508475870958vmin currentColor;
}
.background span:nth-child(36) {
    color: var(--bg-accent-2);
    top: 28%;
    left: 99%;
    animation-duration: 169s;
    animation-delay: -184s;
    transform-origin: 16vw 17vh;
    box-shadow: -6vmin 0 0.9366541120645393vmin currentColor;
}
.background span:nth-child(37) {
    color: var(--bg-accent-2);
    top: 60%;
    left: 98%;
    animation-duration: 134s;
    animation-delay: -168s;
    transform-origin: 17vw 17vh;
    box-shadow: -6vmin 0 1.0775195734965424vmin currentColor;
}
.background span:nth-child(38) {
    color: var(--bg-accent-3);
    top: 67%;
    left: 57%;
    animation-duration: 74s;
    animation-delay: -186s;
    transform-origin: 21vw 18vh;
    box-shadow: 6vmin 0 1.5828043096147015vmin currentColor;
}
.background span:nth-child(39) {
    color: var(--bg-accent-1);
    top: 8%;
    left: 82%;
    animation-duration: 35s;
    animation-delay: -168s;
    transform-origin: 4vw 17vh;
    box-shadow: 6vmin 0 1.4406769205211007vmin currentColor;
}
.background span:nth-child(40) {
    color: var(--bg-accent-1);
    top: 63%;
    left: 1%;
    animation-duration: 70s;
    animation-delay: -199s;
    transform-origin: -13vw -3vh;
    box-shadow: -6vmin 0 1.4988969464012551vmin currentColor;
}
.background span:nth-child(41) {
    color: var(--bg-accent-3);
    top: 98%;
    left: 69%;
    animation-duration: 158s;
    animation-delay: -55s;
    transform-origin: 16vw 23vh;
    box-shadow: 6vmin 0 1.350150781686514vmin currentColor;
}
.background span:nth-child(42) {
    color: var(--bg-accent-2);
    top: 78%;
    left: 86%;
    animation-duration: 201s;
    animation-delay: -91s;
    transform-origin: 16vw -20vh;
    box-shadow: -6vmin 0 0.9376765444691209vmin currentColor;
}
.background span:nth-child(43) {
    color: var(--bg-accent-3);
    top: 80%;
    left: 14%;
    animation-duration: 69s;
    animation-delay: -55s;
    transform-origin: -8vw -24vh;
    box-shadow: -6vmin 0 1.4537031521174226vmin currentColor;
}
.background span:nth-child(44) {
    color: var(--bg-accent-2);
    top: 19%;
    left: 91%;
    animation-duration: 148s;
    animation-delay: -113s;
    transform-origin: -8vw 21vh;
    box-shadow: -6vmin 0 1.7124201431758679vmin currentColor;
}
.background span:nth-child(45) {
    color: var(--bg-accent-1);
}